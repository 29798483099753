export const broadcastMessage = async (methodName: string, message: any, token: string) => {
    const url = `${process.env.REACT_APP_SIGNALR_URL}/api/${methodName}`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(message)
    });

    if (response.ok) {
        console.log("Message broadcasted successfully");
    } else {
        console.error("Failed to broadcast message");
    }
}