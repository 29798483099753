import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import { useNavigate } from "react-router-dom";
import { paths } from "../constants/navigation/paths";

export default function SupportProcessManagerButton() {
  const navigate = useNavigate();

  return (
    <ListItemButton>
      <ListItemIcon>
        <BuildIcon />
      </ListItemIcon>
      <ListItemText
        primary="Support Processes"
        onClick={() => navigate(`${paths.SupportProcessManager}`)}
      />
    </ListItemButton>
  );
}
