import React from "react";
import { Card, CardContent, Typography, Box, styled } from "@mui/material";

interface TenantDetailsCardProps {
  title: string;
  children?: React.ReactNode;
}

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  overflow: "hidden",
  width: "100%",
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(2),
  color: theme.palette.common.white,
}));

const TenantDetailsCard: React.FC<TenantDetailsCardProps> = ({
  title,
  children,
}) => {
  return (
    <StyledCard>
      <StyledHeader>
        <Typography variant="h6" align="left">
          {title}
        </Typography>
      </StyledHeader>
      <CardContent>{children}</CardContent>
    </StyledCard>
  );
};

export default TenantDetailsCard;
