import { useEffect, useState } from "react";
import { LoadingOverlay } from "../common/LoadingOverlay"
import TenantDetailsCard from "./TenantDetailsCard"
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { ContactInformation, Tenant, TenantPermissions } from "../../interfaces/tenants/Tenant";
import { paths } from "../../constants/navigation/paths";
import { useNavigate } from "react-router-dom";
import { getTenantToTenantPermissions } from "../../services/tenantToTenantPermissionsService";
import { TenantToTenantPermissionModal } from "./TenantToTenantPermissionModal";

interface GrantPermissionsProps {
  tenantId: string;
  tenants: Tenant[];
}

export const GrantPermissions: React.FC<GrantPermissionsProps> = ({tenantId, tenants}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [givenPermissionsSearchText, setGivenPermissionsSearchText] = useState("");
    const [grantedPermissionsData, setGrantedPermissionsData] = useState<TenantPermissions[]>([]);
    const [givenPermissionsData, setGivenPermissionsData] = useState<TenantPermissions[]>([]);
    const navigate = useNavigate();
    
    const fetch = (tenantId: string) => {
      setIsLoading(true);
      getTenantToTenantPermissions(tenantId)
        .then((response) => {
          const tenantPermissions = response!.permissionsGivenToTenants.map((tenantPermission: TenantPermissions) => { 
            return {
                  ...tenantPermission,
                }
              });
          setGrantedPermissionsData(tenantPermissions);

          const givenPermissions = response!.permissionsGivenByTenants.map((tenantPermission: TenantPermissions) => {  
            return {
                  ...tenantPermission,
                }
              });
          setGivenPermissionsData(givenPermissions);
        })
        .then(() => setIsLoading(false));
    };

    const filterGrantedPermissionsListbySearchTerm = (searchTerm: string) => {
      searchTerm = searchTerm.toUpperCase();
  
      const filteredPermissions = grantedPermissionsData.map((p) => {
        if (
          p.tenantId.toUpperCase().includes(searchTerm)
        ) {
          p.isHidden = false;
        } else {
          p.isHidden = true;
        }
  
        return p;
      });
  
      setGrantedPermissionsData(filteredPermissions);
    };

    const filterGivenPermissionsListbySearchTerm = (searchTerm: string) => {
      searchTerm = searchTerm.toUpperCase();
  
      const filteredPermissions = givenPermissionsData.map((p) => {
        if (
          p.tenantId.toUpperCase().includes(searchTerm)
        ) {
          p.isHidden = false;
        } else {
          p.isHidden = true;
        }
  
        return p;
      });
  
      setGivenPermissionsData(filteredPermissions);
    };

    const searchGrantedPermissions = (searchText: string) => {
      setSearchText(searchText);
      filterGrantedPermissionsListbySearchTerm(searchText);
    };

    const searchGivenPermissions = (searchText: string) => {
      setGivenPermissionsSearchText(searchText);
      filterGivenPermissionsListbySearchTerm(searchText);
    };

    const handlePermissionModalClose = (permissions: TenantPermissions[]) => {
      setGrantedPermissionsData(permissions);
    }

    const handleRowClick = (path: string, tenantPermissionData: TenantPermissions) => {
      const contactInformation = tenants.find(tenant => tenant.tenantId === tenantPermissionData.tenantId)?.contactInformation as ContactInformation;
      navigate(`${path}/${tenantPermissionData.tenantId}`, {
        state: { tenantPermissionData, contactInformation },
      });
    };

    useEffect(() => fetch(tenantId), []);

    const rows: TenantPermissions[] = grantedPermissionsData;
    const rowsGivenPermissions: TenantPermissions[] = givenPermissionsData;
    return (
      <Box>
        <TenantDetailsCard title="Grant Permissions">
          
          <Box style={{ position: "relative" }}>
            {isLoading && <LoadingOverlay />}
            <Box display="flex" justifyContent="space-between" alignItems="end">
              <TenantToTenantPermissionModal tenantId={tenantId} tenants={tenants} permissionsData={grantedPermissionsData} callback={handlePermissionModalClose} />
              <TextField
                  data-testid="searchTextField"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) =>
                    searchGrantedPermissions((e.target as HTMLInputElement).value as string)
                  }
                />
            </Box>
            <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Permissions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    if (!row.isHidden) {
                      return (
                        <TableRow
                          key={row.tenantId}
                          onClick={() => handleRowClick(paths.GrantPermissions, row)}
                          hover
                          style={{ cursor: "pointer" }}
                        >
                          <TableCell>{row.tenantId}</TableCell>
                          <TableCell>{row.permissions.join(", ")}</TableCell>
                        </TableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </Box>
        </TenantDetailsCard>
        <span style={{ display: "block", height: "20px" }} />
        <TenantDetailsCard title="Given Permissions">
        <Box style={{ position: "relative" }}>
          {isLoading && <LoadingOverlay />}
          <Box display="flex" justifyContent="flex-end">
            <TextField
              data-testid="searchTextFieldGivenPermissions"
              placeholder="Search"
              value={givenPermissionsSearchText}
              onChange={(e) =>
                searchGivenPermissions((e.target as HTMLInputElement).value as string)
              }
            />
          </Box>

          <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Permissions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsGivenPermissions.map((row) => {
                  if (!row.isHidden) {
                    return (
                      <TableRow
                        key={row.tenantId}
                        onClick={() => handleRowClick(paths.GivenPermissions, row)}
                        hover
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>{row.tenantId}</TableCell>
                        <TableCell>{row.permissions.join(", ")}</TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </Box>
      </TenantDetailsCard>
    </Box>);
}