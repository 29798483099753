import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const LogOutButton = () => {
    const { instance } = useMsal();

    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/" // redirects the top level app after logout
            });
        }
    }

    return (
        <Button variant="contained" className="ml-auto" onClick={() => handleLogout("popup")}>Log Out</Button>
    );
}