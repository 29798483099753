import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import axios from 'axios';
import { DeadLetterMessage } from "./DeadLetters";
import { getToken } from "../utilities/getToken";

export type DeadLetterRequeueProps = {
    disabled: boolean
    message: DeadLetterMessage
}

export default function DeadLetterRequeue(props: DeadLetterRequeueProps){
    const [open, setOpen] = useState(false);

    const baseURL = process.env.REACT_APP_API_URL + `v1/SyncErrors`;
    const token = getToken();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        axios.post(baseURL, props.message, {
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Content-Type' : 'application/json',
                'Accept' : 'application/json'
            },
          }).then((response) => {
            if (response.status === 200)
                window.location.reload();
          });
    };

    return (
        <div>
          <Button disabled={props.disabled} variant="outlined" color="inherit" onClick={globalThis.unsafe ? handleOk : handleClickOpen}>
            Requeue
          </Button>
          <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Requeue</DialogTitle>
                <DialogContent>
                    Are you sure you want to requeue this message? Note that if you made any changes to the original message body, they WILL be sent.
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                    No
                    </Button>
                    <Button onClick={handleOk}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>);
}