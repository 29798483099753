import { Box, IconButton, List, ListItem, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SupportUtilityPaper from "../styled-components/SupportUtilityPaper"
import Title from "../Title"
import { useLocation, useNavigate } from "react-router-dom";
import { ContactInformation, TenantPermissions } from "../../interfaces/tenants/Tenant";

export const PermissionDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {tenantPermissionData, contactInformation} = location.state as {tenantPermissionData: TenantPermissions, contactInformation: ContactInformation};

    const onBackButtonClick = () => {
        navigate(-1);
      };

    return (<SupportUtilityPaper>
        <Box display="flex" alignItems="center">
            <IconButton onClick={onBackButtonClick}>
            <ArrowBackIcon />
            </IconButton>
            <Title>Permission Details</Title>
        </Box>
        <Box>
            <Box>
                <Typography variant="h6">Tenant ID:</Typography>
                {tenantPermissionData.tenantId}
            </Box>
            <br/>
            <Box>
                <Typography variant="h6">Name:</Typography>
                {contactInformation.firstName} {contactInformation.lastName}
            </Box>
            <br/>
            <Box>
                <Typography variant="h6">Company:</Typography>
                {contactInformation.company} 
            </Box>
            <br/>
            <Box>
                <Typography variant="h6">Email:</Typography>
                {contactInformation.email}
            </Box>
            <br/>
            <Box>
                <Typography variant="h6">Address:</Typography>
                {contactInformation.address.line1 ? `${contactInformation.address.line1} ${contactInformation.address.line2} ${contactInformation.address.city}, ${contactInformation.address.state}, ${contactInformation.address.zipCode}` : "N/A"}
            </Box>
            <br/>
            <Box>
                <Typography variant="h6">Permissions:</Typography>
                <List>
                {tenantPermissionData.permissions.map(permission => <ListItem key={permission}>- {permission}</ListItem>)}
                </List>
                
            </Box>
        </Box>
    </SupportUtilityPaper>)
}
