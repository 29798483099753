import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import axios from 'axios';
import { getToken } from "../utilities/getToken";

export type DeadLetterResolveProps = {
    queueName: string | null,
    messageId: string | null
}

export default function DeadLetterResolve(props: DeadLetterResolveProps){
    const [open, setOpen] = useState(false);

    const baseURL = process.env.REACT_APP_API_URL + `v1/SyncErrors`;
    const token = getToken();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        axios.post(`${baseURL}/${props.queueName}/${props.messageId}`, {}, {
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Content-Type' : 'application/json',
                'Accept' : 'application/json'
            },
          }).then((response) => {
            if (response.status === 200)
                window.location.reload();
          });
    };

    return (
        <div>
          <Button variant="outlined" color="inherit" onClick={globalThis.unsafe ? handleOk : handleClickOpen}>
            Resolve
          </Button>
          <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Mark Resolved</DialogTitle>
                <DialogContent>
                    Are you sure you want to mark this error as resolved?
                    Note that this action does NOT reprocess the message.
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                    No
                    </Button>
                    <Button onClick={handleOk}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>);
}