import * as React from "react";
import axios from "axios";
import { format } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import DeadLetterDetails from "./DeadLetterDetails";
import { useParams } from "react-router-dom";
import DeadLetterResolve from "./DeadLetterResolve";
import DeadLetterRequeue from "./DeadLetterRequeue";
import { getDeadLetterMetadata } from "../utilities/getDeadLetterMetadata";
import { getToken } from "../utilities/getToken";
import DeadLetterResolveAll from "./DeadLetterResolveAll";
import DeadLetterRequeueAll from "./DeadLetterRequeueAll";
import SupportUtilityPaper from "./styled-components/SupportUtilityPaper";

export type DeadLetterMessage = {
  applicationProperties: {} | null;
  messageId: string | null;
  correlationId: string | null;
  correlationPropertyName: string | null;
  eventName: string | null;
  enqueuedTime: string;
  locationId: string | null;
  tenantId: string | null;
  reason: string | null;
  messageBody: string | null;
  description: string | null;
};

export default function DeadLetters() {
  const baseURL = process.env.REACT_APP_API_URL + "v1/SyncErrors";
  const token = getToken();
  const { sourceQueue } = useParams();
  const [syncErrors, setSyncErrors] = React.useState<DeadLetterMessage[]>([]);

  const fetch = () => {
    axios
      .get(`${baseURL}/${sourceQueue}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const syncErrorsWithMetadata = response.data.syncErrors.map(
          (err: DeadLetterMessage) => {
            const metadata = getDeadLetterMetadata(
              err.eventName!,
              err.messageBody
            );
            return {
              ...err,
              ...metadata,
            };
          }
        );
        setSyncErrors(syncErrorsWithMetadata);
      });
  };

  React.useEffect(fetch, [sourceQueue, DeadLetterResolve.call]);

  const resolveAllEnabed: boolean =
    sourceQueue === "historical-sales-request-sync" ||
    sourceQueue === "historical-sales-sync";
  const rows: DeadLetterMessage[] = syncErrors;
  const messageIds: (string | null)[] = syncErrors.map((obj) => obj.messageId);

  return (
    <SupportUtilityPaper>
      <Title>Dead Letter Messages - {sourceQueue}</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Error</TableCell>
            <TableCell>Tenant</TableCell>
            <TableCell></TableCell>
            <TableCell>
              <DeadLetterResolveAll
                disabled={!resolveAllEnabed}
                queueName={sourceQueue}
                messageIds={messageIds}
              ></DeadLetterResolveAll>
            </TableCell>
            <TableCell>
              <DeadLetterRequeueAll
                disabled={false}
                queueName={sourceQueue}
                messages={rows}
              ></DeadLetterRequeueAll>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.messageId as React.Key}>
              <TableCell>
                {format(Date.parse(row.enqueuedTime), "MM/dd/yyyy hh:mm:ss aa")}
              </TableCell>
              <TableCell>{row.reason}</TableCell>
              <TableCell>{row.tenantId}</TableCell>
              <TableCell>
                <DeadLetterDetails
                  applicationProperties={row.applicationProperties}
                  enqueuedTime={row.enqueuedTime}
                  eventName={row.eventName}
                  messageId={row.messageId}
                  messageBody={row.messageBody}
                  reason={row.reason}
                  tenantId={row.tenantId}
                  locationId={row.locationId}
                  correlationId={row.correlationId}
                  correlationPropertyName={row.correlationPropertyName}
                  description={row.description}
                />
              </TableCell>
              <TableCell>
                <DeadLetterResolve
                  queueName={row.eventName}
                  messageId={row.messageId}
                ></DeadLetterResolve>
              </TableCell>
              <TableCell>
                <DeadLetterRequeue
                  disabled={false}
                  message={row}
                ></DeadLetterRequeue>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SupportUtilityPaper>
  );
}
