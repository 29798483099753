import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import axios from 'axios';
import { getToken } from "../utilities/getToken";

export type DeadLetterResolveAllProps = {
    disabled: boolean,
    queueName: string | undefined,
    messageIds: (string | null)[] | undefined
}

export default function DeadLetterResolveAll(props: DeadLetterResolveAllProps){
    const [open, setOpen] = useState(false);
    const messagesToSend = 25;
    const baseURL = process.env.REACT_APP_API_URL + `v1/SyncErrors`;
    const token = getToken();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        if(props.messageIds === undefined){
            return;
        }

        let messageIds = props.messageIds.splice(0, messagesToSend);
        let partitionKey = props.queueName ?? "Unknown";

        axios.post(`${baseURL}/${partitionKey}/resolveAll`, messageIds, {
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Content-Type' : 'application/json',
                'Accept' : 'application/json'
            },
        }).then((response) => {
            if (response.status === 200)
                window.location.reload();
            if (response.status !== 200)
                alert(`${response.status} ${response.statusText}`)
        });
    };

    return (
        <div>
          <Button disabled={props.disabled} variant="outlined" color="inherit" onClick={globalThis.unsafe ? handleOk : handleClickOpen}>
            Resolve All
          </Button>
          <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Resolve Top {messagesToSend > props.messageIds!.length ? props.messageIds!.length : messagesToSend} Errors</DialogTitle>
                <DialogContent>
                    Are you sure you want to resolve the top {messagesToSend > props.messageIds!.length ? props.messageIds!.length : messagesToSend} errors?
                    Note that this action does NOT reprocess messages and will lose any messages from this process in the DeadLetter Queue.
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                    No
                    </Button>
                    <Button onClick={handleOk}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>);
}