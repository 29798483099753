import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../utilities/authConfig";
import { Button } from "@mui/material";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const LogInButton = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState('');

    const name = accounts[0] && accounts[0].name;

    function RequestAccessToken() {
        const request = {
            ...loginRequest,
            account: accounts[0]
        };
    
        // Silently acquires an access token which is then attached to API requests
        instance.acquireTokenSilent(request).then((response) => {
            window.localStorage.setItem("Token", response.accessToken);
            setAccessToken(response.accessToken);
        }).catch((e) => {
            instance.acquireTokenPopup(request).then((response) => {
                window.localStorage.setItem("Token", response.accessToken);
                setAccessToken(response.accessToken);
            });
        });
    }

    const handleLogin = (loginType: string) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
            }).then(() => RequestAccessToken());
        }
    }
    return (
        <Button variant="contained" className="ml-auto" onClick={() => handleLogin("popup")}>Log in</Button>
    );
}