import Title from "./Title";
import * as React from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { getToken } from "../utilities/getToken";
import { useNavigate } from "react-router-dom";
import { paths } from "../constants/navigation/paths";
import SupportUtilityPaper from "./styled-components/SupportUtilityPaper";
import { Tenant } from "../interfaces/tenants/Tenant";

export default function Tenants() {
  const baseURL = process.env.REACT_APP_API_URL + "v1/Tenants";
  const token = getToken();
  const [tenantData, setTenantData] = React.useState<Tenant[]>([]);
  const [allTenants, setAllTenants] = React.useState<Tenant[]>([]);
  const [searchText, setSearchText] = React.useState("");

  const navigate = useNavigate();

  const fetch = () => {
    axios
      .get(`${baseURL}/All`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const tenants = response.data.map((tenant: Tenant) => {
          return {
            ...tenant,
          };
        });
        setAllTenants(tenants.map((tenant: Tenant) => ({ ...tenant, isHidden: false })));
        setTenantData(tenants);
      });
  };

  const filterListbySearchTerm = (searchTerm: string) => {
    searchTerm = searchTerm.toUpperCase();

    const filteredTenants = tenantData.map((u) => {
      if (
        u.businessName?.toUpperCase().includes(searchTerm) ||
        u.tenantId?.toUpperCase().includes(searchTerm)
      ) {
        u.isHidden = false;
      } else {
        u.isHidden = true;
      }

      return u;
    });

    setTenantData(filteredTenants);
  };

  const searchTenants = (searchText: string) => {
    setSearchText(searchText);
    filterListbySearchTerm(searchText);
  };

  const handleRowClick = (selectedTenantData: Tenant) => {
    navigate(`${paths.Tenants}/${selectedTenantData.tenantId}`, {
      state: { selectedTenantData, allTenants },
    });
  };

  React.useEffect(fetch, []);

  const rows: Tenant[] = tenantData;
  return (
    <SupportUtilityPaper>
      <Title>Tenants</Title>
      <TextField
        data-testid="searchTextField"
        placeholder="Search"
        value={searchText}
        onChange={(e) =>
          searchTenants((e.target as HTMLInputElement).value as string)
        }
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            if (!row.isHidden) {
              return (
                <TableRow
                  key={row.tenantId}
                  onClick={() => handleRowClick(row)}
                  hover
                  style={{ cursor: "pointer" }}
                >
                  <TableCell>{row.businessName}</TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </SupportUtilityPaper>
  );
}
