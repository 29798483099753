import axios from "axios";
import { ServiceLog } from "../interfaces/service-logs/ServiceLog";
import { getToken } from "../utilities/getToken";
import { ReadLogResponse } from "../constants/responses/ReadLogResponse";

let token: string | null = null;
const baseURL = process.env.REACT_APP_API_URL;
const ricsXBaseURL = process.env.REACT_APP_RICSX_API_URL;

export const getRicsXToken = async (): Promise<string | null> => {
  const apiToken = getToken();
  try {
    const response = await axios.get(`${baseURL}v2/Auth/GetRicsXToken/`, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
      },
    });
    token = response.data.token;
    return token;
  } catch (error) {
    console.error("Failed to retrieve token:", error);
    return null;
  }
};

export const fetchServiceLogs = async (
  customFilterString?: string,
  page: number = 0,

): Promise<ServiceLog[]> => {
  const getPastTenSecondsDateTime = (): string => {
    const now = new Date();
    const past = new Date(now.getTime() - 10000);
    return past.toISOString();
  };
  try {
    const token = await getRicsXToken();
    if (!token) {
      throw new Error("No token available for authentication");
    }

    const defaultFilterString = `Created gt ${getPastTenSecondsDateTime()}`;
    const skip = page * 200;

    const requestData = {
      IsSupportLog: true,
      FilterString: customFilterString || defaultFilterString,
      OrderBy: "Created desc",
      Skip: skip,
      Top: 200,
    };
    const response = await axios.post<ReadLogResponse>(
      `${ricsXBaseURL}ReadLog/`,
      requestData,
      {
        headers: {
          Token: token,
        },
      }
    );

    return response.data.Logs;
  } catch (error) {
    console.error("Failed to fetch support logs:", error);
    throw error;
  }
};
