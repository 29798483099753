import { Box, CircularProgress } from "@mui/material";

export const LoadingOverlay = () => {
  return (
    <Box
      sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
      pointerEvents: "none"
      }}
    >
      <CircularProgress />
    </Box>
  );
};
