import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import MonitorIcon from "@mui/icons-material/Monitor";
import { useNavigate } from "react-router-dom";
import { paths } from "../constants/navigation/paths";

export default function ServiceLogsButton() {
  const navigate = useNavigate();

  return (
    <ListItemButton>
      <ListItemIcon>
        <MonitorIcon />
      </ListItemIcon>
      <ListItemText
        primary="Service Logs"
        onClick={() => navigate(`${paths.ServiceLogs}`)}
      />
    </ListItemButton>
  );
}
