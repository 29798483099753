import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";
import { paths } from "../constants/navigation/paths";

export default function ManualProductSyncButton() {
  const navigate = useNavigate();

  return (
    <ListItemButton>
      <ListItemIcon>
        <SyncIcon />
      </ListItemIcon>
      <ListItemText
        primary="Manual Product Sync"
        onClick={() => navigate(`${paths.ManualProductSync}`)}
      />
    </ListItemButton>
  );
}
