import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ElderlyIcon from "@mui/icons-material/Elderly";
import { useNavigate } from "react-router-dom";
import { paths } from "../constants/navigation/paths";

export default function HistoricalSaleSyncButton() {
  const navigate = useNavigate();

  return (
    <ListItemButton>
      <ListItemIcon>
        <ElderlyIcon />
      </ListItemIcon>
      <ListItemText
        primary="Historical Sale Resync"
        onClick={() => navigate(`${paths.HistoricalSync}`)}
      />
    </ListItemButton>
  );
}
