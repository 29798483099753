import { useState } from "react";
import { Tenant } from "../../interfaces/tenants/Tenant";
import TenantDetailsCard from "./TenantDetailsCard";
import { Box, Button, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { updateTenant } from "../../services/tenantsService";
import { BrandingSettings } from "../../interfaces/brandingSettings";

interface AccountDetailsProps {
  tenant: Tenant;
  brandingSettings?: BrandingSettings;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
  tenant,
  brandingSettings,
}) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(tenant.businessName);
  const [addressLine1, setAddressLine1] = useState(
    tenant.contactInformation.address.line1 || "N/A"
  );
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);

    const updatedTenant = {
      ...tenant,
      businessName: name,
      contactInformation: {
        ...tenant.contactInformation,
        address: {
          ...tenant.contactInformation.address,
          line1: addressLine1,
        },
      },
    };

    try {
      const result = await updateTenant(updatedTenant, tenant.tenantId);
      if (result) {
        console.log("Tenant updated successfully:", result);
      } else {
        console.error("Failed to update tenant");
      }
    } catch (error) {
      console.error("Error updating tenant:", error);
    }
  };

  return (
    <TenantDetailsCard title="Account Details">
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Typography variant="h5">User Details</Typography>
          {isEditing ? (
            <Button
              variant="contained"
              startIcon={<SaveAsIcon />}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={handleEditClick}
            >
              Edit
            </Button>
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputProps={{ readOnly: !isEditing }}
            />
          </Box>
          <Box>
            <TextField
              label="Address Line 1"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
              InputProps={{ readOnly: !isEditing }}
            />
          </Box>
          {brandingSettings && (
            <Box>
              <Box
                component="img"
                src={brandingSettings.logos?.smallLogoUrl}
                sx={{
                  width: 150,
                  height: 150,
                  border: "2px gray",
                }}
              />
              <Typography
                sx={{ color: theme.palette.common.gray, fontSize: 14 }}
              >
                Image 500x500
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </TenantDetailsCard>
  );
};

export default AccountDetails;
