
export const paths = {
  Default: "/",
  Tenants: "/tenants",
  Identities: "/identities",
  SyncErrors: "/sync-errors",
  ECommerceSync: "/ecommerce-sync",
  HistoricalSync: "/historical-sync",
  ManualProductSync: "/manual-product-sync",
  SupportProcessManager: "/support-process-manager",
  ServiceLogs: "/service-logs",
  GrantPermissions: "/grant-permissions",
  GivenPermissions: "/given-permissions",
} as const;
