export function getDeadLetterMetadata(queueName: string, messageBody: string | null): DeadLetterMetadata {
    let formatedMessageBody = messageBody === "null" || messageBody === null ? JSON.stringify("Unknown") : messageBody;
    let message = JSON.parse(formatedMessageBody);
    if (!Array.isArray(message)){
        message = [message];
    }
    const messageTypeMap = new Map<string, DeadLetterMetadata>([
        ['sale-to-rics9',  {correlationId: message[0].SaleId?.toString(), correlationPropertyName: 'SaleId', tenantId: message[0].TenantId?.toString() ?? '', locationId: message[0].LocationId?.toString()}],
        ['sale-to-ricsx',  {correlationId: message[0].SaleId?.toString(), correlationPropertyName: 'SaleId', tenantId: message[0].TenantId?.toString() ?? '', locationId: message[0].LocationId?.toString()}],
        ['create-sale',  {correlationId: message[0].SaleId?.toString(), correlationPropertyName: 'SaleId', tenantId: message[0].TenantId?.toString() ?? '', locationId: message[0].LocationId?.toString()}],
        ['send-receipt',  {correlationId: message[0].SaleId?.toString(), correlationPropertyName: 'SaleId', tenantId: message[0].TenantId?.toString() ?? '', locationId: message[0].LocationId?.toString()}],
        ['historical-sales-sync',  {correlationId: '', correlationPropertyName: '', tenantId: message[0].TenantId?.toString() ?? '', locationId: ''}],
        ['historical-sales-request-sync',  {correlationId: '', correlationPropertyName: '', tenantId: message[0].TenantId?.toString() ?? '', locationId: ''}],
        ['ecommerce-sale-sync-complete',  {correlationId: message[0].SaleId?.toString(), correlationPropertyName: 'SaleId', tenantId: message[0].TenantId?.toString() ?? '', locationId: message[0].LocationId?.toString()}],
        ['ricsx-inventory-update',  {correlationId: '', correlationPropertyName: '', tenantId: '', locationId: ''}],
        ['ricsx-transaction-update',  {correlationId: message[0].ProductItemId?.toString(), correlationPropertyName: 'AccountId', tenantId: '', locationId: ''}],
        ['amazon-inventory-action',  {correlationId: message[0].AccountId?.toString(), correlationPropertyName: 'AccountId', tenantId: '', locationId: ''}],
        ['amazon-orders-action',  {correlationId: message[0].AccountId?.toString(), correlationPropertyName: 'AccountId', tenantId: '', locationId: ''}],
        ['amazon-pricing-action',  {correlationId: message[0].AccountId?.toString(), correlationPropertyName: 'AccountId', tenantId: '', locationId: ''}],
        ['amazon-product-cache-action',  {correlationId: message[0].AccountId?.toString(), correlationPropertyName: 'AccountId', tenantId: '', locationId: ''}],
        ['amazon-returns-action',  {correlationId: message[0].AccountId?.toString(), correlationPropertyName: 'AccountId', tenantId: '', locationId: ''}],
    ]);

    return messageTypeMap.get(queueName)!;
}

export type DeadLetterMetadata = {
    correlationId: string | undefined,
    correlationPropertyName: string | undefined,
    tenantId: string | undefined,
    locationId : string | undefined
}
