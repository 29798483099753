import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import './App.css';
import Dashboard from './components/Dashboard';

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Dashboard/>
    </LocalizationProvider>
  );
}

export default App;
