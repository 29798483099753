import React from "react";
import { Paper, PaperProps } from "@mui/material";

interface SupportUtilityPaperProps extends PaperProps {
  children: React.ReactNode;
}

const SupportUtilityPaper: React.FC<SupportUtilityPaperProps> = ({
  children,
}) => {
  return (
    <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
      {children}
    </Paper>
  );
};

export default SupportUtilityPaper;
