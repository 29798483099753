import {
  Icon,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { useNavigate } from "react-router-dom";
import { paths } from "../constants/navigation/paths";

export default function ECommerceSyncButton() {
  const navigate = useNavigate();

  return (
    <ListItemButton>
      <ListItemIcon>
        <SyncIcon />
      </ListItemIcon>
      <ListItemText
        primary="ECommerce Sync"
        onClick={() => navigate(`${paths.ECommerceSync}`)}
      />
    </ListItemButton>
  );
}
