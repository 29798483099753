import axios from "axios";
import { getToken } from "../utilities/getToken";
import { BrandingSettings } from "../interfaces/brandingSettings";

const baseURL = process.env.REACT_APP_API_URL;

export const getBrandingSettings = async (
  tenantId: string
): Promise<BrandingSettings[] | null> => {
  const apiToken = getToken();
  try {
    const response = await axios.get<BrandingSettings[]>(
      `${baseURL}v2/Branding/Support/Settings/`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        params: {
          tenantId,
        },
      }
    );
    const updatedTenant = response.data;
    return updatedTenant;
  } catch (error) {
    console.error("Failed to update tenant:", error);
    return null;
  }
};
