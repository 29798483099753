import axios from "axios";
import { getToken } from "../utilities/getToken";
import { ImpersonationRequest } from "../constants/requests/ImpersonationRequest";
import { ImpersonationResponse } from "../constants/responses/ImpersonationResponse";

const baseURL = process.env.REACT_APP_API_URL;

export const getImpersonateToken = async (tenantId: string, identityId: string) : Promise<ImpersonationResponse | undefined> => {
    const apiToken = getToken();
    const endpoint = 'v1/Auth/ImpersonateIdentity';

    const payload : ImpersonationRequest = {
        TenantId: tenantId,
        IdentityId: identityId,
        TokenType: 'ShortLived'
    };

    try {
        const response = await axios.post<ImpersonationResponse>(
          `${baseURL}${endpoint}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${apiToken}`,
            },
          }
        );
        const impersonationResponse = response.data;
        return impersonationResponse;
      } catch (error) {
        console.error("Failed to retrieve token:", error);
        Promise.reject(error);
      }
};