import { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material/";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Tenant } from "../../interfaces/tenants/Tenant";
import AccountDetails from "./AccountDetails";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Title from "../Title";
import { paths } from "../../constants/navigation/paths";
import { Rics9TokenDetails } from "./Rics9TokenDetails";
import { getRics9Token } from "../../services/rics9TokenService";
import { Rics9Token } from "../../interfaces/tenants/Rics9Token";
import { getBrandingSettings } from "../../services/brandingSettingsService";
import { BrandingSettings } from "../../interfaces/brandingSettings";
import { Identities } from "./Identities";
import { GrantPermissions } from "./GrantPermissions";

export const TenantDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [rics9Token, setRics9Token] = useState<Rics9Token | undefined>(
    undefined
  );

  const [brandingSettings, setBrandingSettings] = useState<
    BrandingSettings | undefined
  >(undefined);

  const { selectedTenantData, allTenants } = location.state as { selectedTenantData: Tenant, allTenants: Tenant[] };

  const onBackButtonClick = () => {
    navigate(`${paths.Tenants}`);
  };

  const retrieveRics9Token = async () => {
    try {
      const retrievedToken = await getRics9Token(selectedTenantData.tenantId);
      if (retrievedToken) {
        setRics9Token(retrievedToken);
      } else {
        setRics9Token(undefined);
      }
    } catch (error) {
      console.error("Failed to retrieve RICS 9 Token:", error);
    }
  };

  const retrieveBrandingSettings = async () => {
    try {
      const retrievedBrandingSettings = await getBrandingSettings(
        selectedTenantData.tenantId
      );
      if (retrievedBrandingSettings) {
        setBrandingSettings(retrievedBrandingSettings[0]);
      }
    } catch (error) {
      console.error("Failed to retrieve branding settings:", error);
    }
  };

  useEffect(() => {
    retrieveRics9Token();
    retrieveBrandingSettings();
  }, []);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box display="flex" alignItems="center">
        <IconButton onClick={onBackButtonClick}>
          <ArrowBackIcon />
        </IconButton>
        <Title>Tenant Details</Title>
      </Box>
      <AccountDetails tenant={selectedTenantData} brandingSettings={brandingSettings} />
      <Rics9TokenDetails
        rics9Token={rics9Token}
        tenantId={selectedTenantData.tenantId}
      />
      <Identities tenantId={selectedTenantData.tenantId} />
      <GrantPermissions tenantId={selectedTenantData.tenantId} tenants={allTenants} />
    </Box>
  );
};
