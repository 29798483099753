import { Box, Button, IconButton, TextField, Checkbox, Typography } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SupportUtilityPaper from "../styled-components/SupportUtilityPaper"
import Title from "../Title"
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getIdentity, putIdentity } from "../../services/identitiesService";
import { Identity } from "../../interfaces/tenants/Identity";
import { LoadingOverlay } from "../common/LoadingOverlay";
import { SaveIdentityRequest } from "../../constants/requests/SaveIdentityRequest";
import { getImpersonateToken } from "../../services/impersonateIdentityService";

export const IdentityDetails = () => {
    const navigate = useNavigate();
    const { tenantId, identityId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [identityData, setIdentityData] = useState<Identity>({identityId: "", firstName: "", lastName: "", emailAddress: "", phoneNumber: "", roles: []});
    const [mode, setMode] = useState<"view" | "edit">("view");
    
    const buildIdentityRequest = (identityData: Identity) : SaveIdentityRequest => {
        return {
            identityId: identityData.identityId,
            firstName: identityData.firstName,
            lastName: identityData.lastName,
            emailAddress: identityData.emailAddress,
            phoneNumber: identityData.phoneNumber,
            rics9UserId: null,
            roles: identityData.roles || [],
            tenants: [tenantId!]
        };
    }

    const onBackButtonClick = () => {
        navigate(-1);
      };

    const fetch = (identityId: string) => {
        setIsLoading(true);
        getIdentity(identityId)
        .then((response) => {
            setIdentityData(response || {identityId: "", firstName: "", lastName: "", emailAddress: "", phoneNumber: "", roles: []});
            setIsLoading(false);
        });
      };

    // Mesh
    const navigateToMesh = function () {
        getImpersonateToken(tenantId!, identityId!)
        .then((response) => {
            var postfix = response!.token + '&';
            var url = process.env.REACT_APP_MESH_URL

            url = url + postfix;
            window.open(url, '_blank');
        });
    };

    // Portal
	var navigateToPortal= function () {
        getImpersonateToken(tenantId!, identityId!)
        .then((response) => {
            var postfix = response!.token + '&';
            var url = process.env.REACT_APP_PORTAL_URL
            url = url + postfix;
            window.open(url, '_blank');
        });
    };

    const roleToggled = (role: string) => {
        if(identityData.roles === undefined) {identityData.roles = []}
        if (identityData?.roles.find(r => r.identityRole === role) === undefined) {
            setIdentityData({...identityData, roles: [...identityData.roles || [], {identityRole: role, tenantId: tenantId!, locationId: undefined}]});
        } else {
            setIdentityData({...identityData, roles: identityData.roles?.filter(r => r.identityRole !== role) ?? []});
        }
    };

    const saveIdentity = (identityData: Identity) => {
        setIsLoading(true);
        const identityRequest = buildIdentityRequest(identityData);
        putIdentity(identityRequest, identityId === "new" ? "" : identityId || "")
        .then((response) => {
            setIdentityData(response || {identityId: "", firstName: "", lastName: "", emailAddress: "", phoneNumber: "", roles: []});
            setIsLoading(false);
            if (identityId === "new") {
                navigate(-1);
            } else {
                fetch(identityData?.identityId || "");
                setMode("view");
            }
            
        });
    };

    useEffect(() => { 
        if (identityId && identityId !== "new") {
            fetch(identityId);
        } else {
            setMode("edit");
        } 
        
    }, []);

    return (<SupportUtilityPaper>
        {isLoading && <LoadingOverlay />}
        <Box display="flex" alignItems="end" justifyContent="space-between" >
            <Box display="flex" alignItems="center">
                <IconButton onClick={onBackButtonClick}>
                <ArrowBackIcon />
                </IconButton>
                <Title>Identity Details</Title>
            </Box>
            <Box>
                {mode === "view" && <Button
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => setMode("edit")} 
                data-testid="editUserButton"
                >
                    Edit
                </Button>} 
                {mode === "view" && <Button
                variant="contained"
                startIcon={<PeopleAltIcon />}
                onClick={navigateToMesh}
                data-testid="meshButton"
                >
                    Mesh
                </Button>} 
                {mode === "view" && <Button
                variant="contained"
                startIcon={<PeopleAltIcon />}
                onClick={navigateToPortal}
                data-testid="portalButton"
                >
                    Portal
                </Button>}
                {mode !== "view" && <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => saveIdentity(identityData)} 
                data-testid="saveUserButton"
                >
                    Save
                </Button>}
                {mode !== "view" && <Button
                variant="contained"
                startIcon={<CancelIcon />}
                onClick={() => setMode("view")} 
                data-testid="cancelButton"
                >
                    Cancel
                </Button>}
            </Box>

        </Box>
        <Box>
            <TextField 
                InputLabelProps={{ shrink: true }}
                label="First"
                margin="normal"
                variant="outlined" 
                disabled={mode === "view"} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIdentityData({...identityData, firstName: event.target.value});
                  }}
                value={identityData?.firstName}>
            </TextField> 
            <TextField 
                InputLabelProps={{ shrink: true }}
                label="Last"
                margin="normal"
                variant="outlined" 
                disabled={mode === "view"} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIdentityData({...identityData, lastName: event.target.value});
                  }}
                value={identityData?.lastName}>
            </TextField> 
        </Box>
        <Box>
            <TextField 
                InputLabelProps={{ shrink: true }}
                label="Phone Number"
                margin="normal"
                variant="outlined" 
                disabled={mode === "view"} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIdentityData({...identityData, phoneNumber: event.target.value});
                  }}
                value={identityData?.phoneNumber ?? ""}>
            </TextField> 
        </Box>
        <Box>
            <TextField 
                InputLabelProps={{ shrink: true }}
                label="Email"
                margin="normal"
                variant="outlined" 
                disabled={mode === "view"} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIdentityData({...identityData, emailAddress: event.target.value});
                  }}
                value={identityData?.emailAddress}>
            </TextField> 
        </Box>
        <Box>
            <Typography variant="h6">Roles</Typography>
        </Box>
        <Box>
            Rics
            <Checkbox 
                title="Rics" 
                disabled={mode === "view"} 
                onChange={() => roleToggled("Rics")} 
                checked={(identityData?.roles?.find(r => r.locationId === undefined && r.identityRole === "Rics") ?? undefined) !== undefined}/>
        </Box>
        <Box>
            Owner
            <Checkbox 
                title="Owner" 
                disabled={mode === "view"} 
                onChange={() => roleToggled("Owner")} 
                checked={(identityData?.roles?.find(r => r.locationId === undefined && r.identityRole === "Owner") ?? undefined) !== undefined}/>
        </Box>
        <Box>
            Manager
            <Checkbox 
                title="Manager" 
                disabled={mode === "view"} 
                onChange={() => roleToggled("Manager")} 
                checked={(identityData?.roles?.find(r => r.locationId === undefined && r.identityRole === "Manager") ?? undefined) !== undefined}/>
        </Box>
        <Box>
            Salesperson
            <Checkbox 
                title="Salesperson" 
                disabled={mode === "view"} 
                onChange={() => roleToggled("Salesperson")} 
                checked={(identityData?.roles?.find(r => r.locationId === undefined && r.identityRole === "Salesperson") ?? undefined) !== undefined}/>
        </Box>
        <Box>
            User
            <Checkbox 
                title="User" 
                disabled={mode === "view"} 
                onChange={() => roleToggled("User")} 
                checked={(identityData?.roles?.find(r => r.locationId === undefined && r.identityRole === "User") ?? undefined) !== undefined }/>
        </Box>
            
    </SupportUtilityPaper>)
}
