import * as React from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import Title from "../Title";
import { Tenant } from "../../interfaces/tenants/Tenant";
import { getToken } from "../../utilities/getToken";
import axios from "axios";
import SupportUtilityPaper from "../styled-components/SupportUtilityPaper";
import { Autocomplete, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { SupportProcess } from "../../interfaces/support-processes/SupportProcess";
import { broadcastMessage } from "../../utilities/signalr";
import camelcaseKeys from "camelcase-keys";
import { LoadingOverlay } from "../common/LoadingOverlay";
import { format } from "date-fns";

export const SupportProcessManager = () => {
    const tenantsBaseURL = process.env.REACT_APP_API_URL + "v1/Tenants";
    const supportProcessBaseURL = process.env.REACT_APP_API_URL + "v3/Support/SupportProcess";
    const token = getToken();
    const [isLoading, setIsLoading] = React.useState(false);
    const [tenantData, setTenantData] = React.useState<Tenant[]>([]);
    const [processData, setProcessData] = React.useState<SupportProcess[]>([]);
    const [connection, setConnection] = React.useState<HubConnection | null>(null);

    const enqueueProcess = (tenantId: string, processType: string) => {
      setIsLoading(true);
        axios
          .put(`${supportProcessBaseURL}/${tenantId}/${processType}`, null, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (response.status === 202) {
              console.log("Process enqueued successfully");
            }
            setIsLoading(false);
          });
      };

    const fetchSupportProcessSummaries = (tenantId: string | null) => {
        if (tenantId === null) return;
        setIsLoading(true);
        axios
          .get(`${supportProcessBaseURL}/Summary/${tenantId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            if (!response.data.summaries) return
            const processes = response.data.summaries.map((process: SupportProcess) => {
              return {
                ...process,
              };
            });
            setProcessData(processes);
            setIsLoading(false);
          });
      };

    const fetchTenants = () => {
      setIsLoading(true);
        axios
          .get(`${tenantsBaseURL}/All`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            const tenants = response.data.map((tenant: Tenant) => {
              return {
                ...tenant,
              };
            });
            setTenantData(tenants);
            setIsLoading(false);
          });
      };

    React.useEffect(() => {
        setConnection(() => {
            var conn = new HubConnectionBuilder()
                .withUrl(process.env.REACT_APP_SIGNALR_URL + "/api")
                .build();
            conn
                .start()
                .then(() => console.log("Connected to SignalR hub"))
                .catch((err) => console.error("Error connecting to hub:", err));
          
            conn.on("GenerateProductCatalog", handleSupportProcessUpdate);
            conn.on("ShopifyInventoryResync", handleSupportProcessUpdate);

            return conn;
            });
        
        fetchTenants();
    }, []);

    const handleSupportProcessUpdate = (message: string) => {
      console.log("Received message:", message);
      let parsedSummary = JSON.parse(message);
      const camelCaseSummary = camelcaseKeys(parsedSummary);
        setProcessData((prevSummaries) => {
          const updatedSummaries = prevSummaries.map((summary) => {
            if (summary.tenantId === camelCaseSummary.tenantId && summary.processType === camelCaseSummary.processType) {
              if (camelCaseSummary.processStatus === "InProgress") {
                camelCaseSummary.lastStarted = new Date(camelCaseSummary.lastStarted);
                camelCaseSummary.lastFinished = summary.lastFinished;
              };
              return camelCaseSummary;
            }
            return summary;
          });
          return updatedSummaries;
        });
      };

    const handleRun = async (tenantId: string, processType: string) => {
      enqueueProcess(tenantId, processType);
      await broadcastMessage("BroadcastSupportProcessUpdate", {
        "processType": processType, 
        "tenantId": tenantId, 
        "processStatus": "InProgress", 
        "lastStarted": new Date(), 
        "lastFinished": processData.find(d => d.tenantId === tenantId && d.processType === processType)?.lastFinished
      }, token!);
    };

    return (
    <SupportUtilityPaper>
      {isLoading && <LoadingOverlay />}
      <Title>Support Processes</Title>
      <Autocomplete
        disablePortal
        options={tenantData.map((option) => option.tenantId)}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Tenant" />}
        onChange={(e, value) => {fetchSupportProcessSummaries(value);}}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Process Type</TableCell>
            <TableCell>Process Status</TableCell>
            <TableCell>Last Started</TableCell>
            <TableCell>Last Finished</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {processData.map((row) => {
              return (
                <TableRow
                  key={row.processType}
                >
                  <TableCell>{row.processType}</TableCell>
                  <TableCell>{row.processStatus}</TableCell>
                  <TableCell>{row.lastStarted ? format(new Date(row.lastStarted!), "MM/dd/yyyy hh:mm:ss aa") : "N/A"}</TableCell>
                  <TableCell>{row.lastFinished ? format(new Date(row.lastFinished!), "MM/dd/yyyy hh:mm:ss aa") : "N/A"}</TableCell>
                  <TableCell><Button variant="outlined" onClick={() => handleRun(row.tenantId, row.processType)}>Run</Button></TableCell>
                </TableRow>
              );
          })}
        </TableBody>
      </Table>
    </SupportUtilityPaper>
    );
}