import TenantDetailsCard from "./TenantDetailsCard";
import { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Rics9Token } from "../../interfaces/tenants/Rics9Token";
import AddIcon from "@mui/icons-material/Add";
import { createRics9Token } from "../../services/rics9TokenService";
import { LoadingOverlay } from "../common/LoadingOverlay";

interface Rics9TokenDetailsProps {
  rics9Token?: Rics9Token;
  tenantId: string;
}

export const Rics9TokenDetails: React.FC<Rics9TokenDetailsProps> = ({
  rics9Token,
  tenantId,
}) => {
  const [tokenValue, setTokenValue] = useState(
    rics9Token ? rics9Token.rics9Token : null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (rics9Token?.rics9Token) {
      setTokenValue(rics9Token.rics9Token);
    }
  }, [rics9Token]);

  const generateGuid = (): string => {
    return "xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      const r = Math.floor(Math.random() * 16);
      return c === "x" ? r.toString(16) : ((r & 0x3) | 0x8).toString(16);
    });
  };

  const handleGenerateTokenClick = async () => {
    const generatedToken = generateGuid();
    setTokenValue(generatedToken);
    setIsLoading(true);

    try {
      const newToken = await createRics9Token(generatedToken, tenantId);

      if (newToken && newToken.rics9Token) {
        setTokenValue(newToken.rics9Token);
      } else {
        console.error("Token creation failed, no token returned.");
      }
    } catch (error) {
      console.error("Error generating token:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TenantDetailsCard title="Rics 9 Token">
      <Box style={{ position: "relative" }}>
        {isLoading && <LoadingOverlay />}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <TextField
              label="R9 Token"
              value={tokenValue ? tokenValue : "No Token Found"}
              disabled={true}
              sx={{ width: "350px" }}
            />
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            {!tokenValue && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateTokenClick}
                startIcon={<AddIcon />}
              >
                Generate Token
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </TenantDetailsCard>
  );
};
