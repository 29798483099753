import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DeadLetters from "./components/DeadLetters";
import ECommerceSync from "./components/ECommerceSync";
import Tenants from "./components/Tenants";
import { TenantDetails } from "./components/tenant-details/TenantDetails";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./utilities/authConfig";
import HistoricalSaleResync from "./components/HistoricalSaleSync";
import { HistoricalSalesRequest } from "./components/HistoricalSaleSync";
import { startOfDay } from "date-fns";
import ManualProductSync, {
  ManualProductSyncRequest,
} from "./components/ManualProductSync";
import ServiceLogs from "./components/ServiceLogs";
import { IdentityDetails } from "./components/tenant-details/IdentityDetails";
import { PermissionDetails } from "./components/tenant-details/PermissionDetails";
import { paths } from "./constants/navigation/paths";
import { IdentityCreate } from "./components/tenant-details/IdentityCreate";
import { SupportProcessManager } from "./components/support-process-manager/SupportProcessManager";

console.log(process.env);

declare global {
  var unsafe: boolean;
}

const msalInstance = new PublicClientApplication(msalConfig);
const historicalSalesRequest: HistoricalSalesRequest = {
  saleId: undefined,
  locationId: undefined,
  tenantId: undefined,
  endDateTime: undefined,
  startDateTime: startOfDay(new Date()),
};
const manualProductrequest: ManualProductSyncRequest = {
  tenantId: undefined,
  skus: undefined,
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    ),
    children: [
      {
        path: "sync-errors/:sourceQueue",
        element: <DeadLetters />,
      },
      {
        path: "historical-sync",
        element: <HistoricalSaleResync message={historicalSalesRequest} />,
      },
      {
        path: "manual-product-sync",
        element: <ManualProductSync message={manualProductrequest} />,
      },
      {
        path: "ecommerce-sync",
        element: <ECommerceSync />,
      },
      {
        path: paths.Tenants,
        element: <Tenants />,
      },
      {
        path: `${paths.Tenants}/:tenantId`,
        element: <TenantDetails />,
      },
      {
        path: `${paths.GivenPermissions}/:tenantId`,
        element: <PermissionDetails/>,
      },
      {
        path: `${paths.GrantPermissions}/:tenantId`,
        element: <PermissionDetails/>,
      },
      {
        path: `${paths.Identities}/:tenantId/:identityId`,
        element: <IdentityDetails />,
      },
      {
        path: "service-logs",
        element: <ServiceLogs />,
      },
      {
        path: "ecommerce-sync",
        element: <ECommerceSync />,
      },
      {
        path: "support-process-manager",
        element: <SupportProcessManager />,
      }
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
