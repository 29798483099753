import axios from "axios";
import { getToken } from "../utilities/getToken";
import { Rics9Token } from "../interfaces/tenants/Rics9Token";

const baseURL = process.env.REACT_APP_API_URL;

export const getRics9Token = async (
  tenantId: string
): Promise<Rics9Token | null> => {
  const apiToken = getToken();
  try {
    const response = await axios.get<Rics9Token>(
      `${baseURL}v2.1/Rics9/ConfigurationToken?tenantId=${tenantId}/`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    var rics9Token = response.data;
    return rics9Token;
  } catch (error) {
    console.error("Failed to retrieve token:", error);
    return null;
  }
};

export const createRics9Token = async (
  token: string,
  tenantId: string
): Promise<Rics9Token | null> => {
  const apiToken = getToken();
  try {
    const response = await axios.post<Rics9Token>(
      `${baseURL}v2.1/Rics9/Support/ConfigurationToken/${token}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
        params: {
          tenantId,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Failed to create Rics9 configuration token:", error);
    return null;
  }
};
