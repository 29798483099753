import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Paper, TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import DeadLetterResolve from './DeadLetterResolve';
import DeadLetterRequeue from './DeadLetterRequeue';
import { DeadLetterMessage } from './DeadLetters';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeadLetterDetailsDialog(props: DeadLetterMessage) {
  const [open, setOpen] = React.useState(false);
  const [hasJsonError, setHasJsonError] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const validateJson = (json: string | null) => {
    if (!json) return json;
    let jsonString = json;
    const jsonObj = JSON.parse(jsonString);
    jsonString = JSON.stringify(jsonObj, undefined, 2)
    return jsonString;
  };

  const [messageBodyValue, setMessageBodyValue] = React.useState(validateJson(props.messageBody));
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let jsonString = event.target.value;
    try {
        const jsonObj = JSON.parse(jsonString);
        setHasJsonError(false);
        jsonString = JSON.stringify(jsonObj, undefined, 2)
    } catch {
        setHasJsonError(true);
    }
    setMessageBodyValue(jsonString);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="inherit" onClick={handleClickOpen}>
        Details
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Dead Letter Message Details
            </Typography>
            <DeadLetterRequeue disabled={hasJsonError || messageBodyValue === "null"} message={{
              ...props,
              messageBody: messageBodyValue
            }} />
            <DeadLetterResolve queueName={props.eventName} messageId={props.messageId}/>
          </Toolbar>
        </AppBar>
        
        <Grid2 container spacing={2}>
          <Grid2 xs>
            <Paper>
              Tenant:
              <TextField fullWidth value={props.tenantId}></TextField>
              Location:
              <TextField fullWidth value={props.locationId}></TextField>
              {props.correlationPropertyName}:
              <TextField fullWidth value={props.correlationId}></TextField>
              Error Type:
              <TextField fullWidth value={props.reason}></TextField>
              Description:
              <TextField fullWidth multiline maxRows={20} value={props.description}></TextField>
            </Paper>

          </Grid2>
          <Grid2 lg={8}>
            <Paper>
              Message Body:
              <TextField fullWidth maxRows={24} multiline error={hasJsonError} value={messageBodyValue} onChange={handleChange}></TextField>
            </Paper>
            
          </Grid2>
        </Grid2>
        
      </Dialog>
    </div>
  );
}
