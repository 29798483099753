import axios from "axios";
import { getToken } from "../utilities/getToken";
import { TenantToTenantPermissions } from "../interfaces/tenants/Tenant";
import { SaveTenantPermissionRequest } from "../constants/requests/SaveTenantPermissionRequest";
import { SaveTenantPermissionResponse } from "../constants/responses/SaveTenantPermissionResponse";

const baseURL = process.env.REACT_APP_API_URL;

export const deleteTenantToTenantPermission = async (
  tenantId: string | null,
  targetTenantId: string | null
): Promise<void> => {
  const apiToken = getToken();
  try {
    await axios.delete(
      `${baseURL}v3/TenantToTenantPermissions/Support/${tenantId}/${targetTenantId}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        }
      }
    );
  } catch (error) {
    console.error("Failed to delete tenant permissions:", error);
  }
};

export const getPermissionsList = async (): Promise<string[] | null> => {
  const apiToken = getToken();
  try {
    const response = await axios.get<string[]>(
      `${baseURL}v3/TenantToTenantPermissions/Support/ListAll`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    var permissionsList = response.data;
    return permissionsList;
  } catch (error) {
    console.error("Failed to retrieve Permissions list:", error);
    return null;
  }
};

export const getTenantToTenantPermissions = async (
    tenantId: string
  ): Promise<TenantToTenantPermissions | null> => {
    const apiToken = getToken();
    try {
      const response = await axios.get<TenantToTenantPermissions>(
        `${baseURL}v3/TenantToTenantPermissions?tenantId=${tenantId}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      var tenantToTenantPermissions = response.data;
      return tenantToTenantPermissions;
    } catch (error) {
      console.error("Failed to retrieve Tenant To Tenant Permissions:", error);
      return null;
    }
  };

  export const putTenantToTenantPermission = async (
    saveTenantPermissionRequest: SaveTenantPermissionRequest,
    tenantId: string | null
  ): Promise<SaveTenantPermissionResponse | null> => {
    const apiToken = getToken();
    try {
      const response = await axios.put<SaveTenantPermissionRequest>(
        `${baseURL}v3/TenantToTenantPermissions/Support/${tenantId}`,
        saveTenantPermissionRequest,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          }
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to save tenant permissions:", error);
      return null;
    }
  };