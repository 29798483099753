import { Modal, Box, TextField, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Grid, Chip } from "@mui/material";
import { Fragment, useState } from "react";
import { TenantToTenantPermissionListModal } from "./TenantToTenantPermissionListModal";
import { Tenant, TenantPermissions } from "../../interfaces/tenants/Tenant";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { SaveTenantPermissionResponse } from "../../constants/responses/SaveTenantPermissionResponse";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  interface GrantPermissionsProps {
    tenantId: string;
    tenants: Tenant[];
    permissionsData: TenantPermissions[];
    callback: (permissions: TenantPermissions[]) => void;
  }

export const TenantToTenantPermissionModal: React.FC<GrantPermissionsProps> = ({ tenantId, tenants, permissionsData, callback }) => {
    const [searchTenantListText, setSearchTenantListText] = useState("");
    const [selectedTenantPermissions, setSelectedTenantPermissions] = useState<TenantPermissions | null>(null);
    const [selectedTenant, setSelectedTenant] = useState<Tenant | null>(null);
    const [tenantList, setTenantList] = useState(tenants);
    const [open, setOpen] = useState(false);
    const [permissions, setPermissions] = useState(() => permissionsData);
    
    const handleTenantListRowClick = (tenant: Tenant) => {
      setSelectedTenant(tenant);
      setSelectedTenantPermissions(permissions.find(permission => permission.tenantId === tenant.tenantId) || null);
    };

    const handleOpenModal = () => {
      setSelectedTenant(null);
      setSelectedTenantPermissions(null);
      setPermissions(permissionsData);
      setOpen(true);
    };

    const handleCloseModal = () => {
      callback(permissions);
      setOpen(false);
    };

    const handlePermissionsSelected = (response: SaveTenantPermissionResponse | null) => {
      if (response === null) {
        setPermissions(permissions.filter(p => p.tenantId !== selectedTenant?.tenantId));
        setSelectedTenantPermissions(null);
        return;
      }

      setPermissions(permissions.find(p => p.tenantId === response?.tenantId) ? permissions.map((p) => {
        if (p.tenantId === response?.tenantId) {
          return {tenantId: response?.tenantId || "", permissions: response?.permissions || []};
        }
        return p;
      }) : [...permissions, {tenantId: response?.tenantId || "", permissions: response?.permissions || []}]);
      setSelectedTenantPermissions({tenantId: selectedTenant?.tenantId || "", permissions: response?.permissions || []});
    }

    const filterTenantListBySearchTerm = (searchTerm: string) => {
        searchTerm = searchTerm.toUpperCase();
    
        const filteredTenants = tenantList.map((t) => {
          if (
            t.businessName.toUpperCase().includes(searchTerm) ||
            t.tenantId.toUpperCase().includes(searchTerm)
          ) {
            t.isHidden = false;
          } else {
            t.isHidden = true;
          }
    
          return t;
        });
    
        setTenantList(filteredTenants);
      };

    const searchTenantList = (searchText: string) => {
        setSearchTenantListText(searchText);
        filterTenantListBySearchTerm(searchText);
      };

    return (
        <Fragment>
            <Button
                variant="contained"
                startIcon={<RocketLaunchIcon />}
                onClick={handleOpenModal} 
                data-testid="createNewPermissionsButton"
                >
                Quick Add
            </Button>
            <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 400 }}>
              <h2 id="parent-modal-title">Select a Tenant</h2>
              <Box>
                  <TenantToTenantPermissionListModal 
                    tenantLabel={selectedTenant?.businessName || ""}
                    tenantId={tenantId} 
                    permissedTenantId={selectedTenant?.tenantId || null} 
                    selectedPermissions={selectedTenantPermissions?.permissions || []}
                    callback={handlePermissionsSelected} />
              </Box>
              <Grid container 
                spacing={1} 
                direction="row"
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px"
                }}>
                    {selectedTenantPermissions?.permissions.map((permission) => {
                        return <Grid item xs={6} md={5}><Chip key={permission} label={permission} color="primary" /></Grid>
                    })}
              </Grid>
              
              <Box>
                <TextField
                  data-testid="searchTextFieldPermissionTenants"
                  placeholder="Search"
                  value={searchTenantListText}
                  onChange={(e) =>
                    searchTenantList((e.target as HTMLInputElement).value as string)
                  }
                />
              </Box>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow >
                        <TableCell>Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tenantList.map((row) => {
                        if (!row.isHidden) {
                          return (
                            <TableRow
                              key={row.tenantId}
                              onClick={() => handleTenantListRowClick(row)}
                              hover
                              style={{ cursor: "pointer" }}
                              selected={selectedTenant?.tenantId === row.tenantId}
                            >
                              <TableCell>{row.businessName}</TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Modal>
        </Fragment>);
}