import * as React from "react";
import axios from "axios";
import { format } from "date-fns";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import { getToken } from "../utilities/getToken";
import { HubConnectionBuilder } from "@microsoft/signalr";
import camelcaseKeys from "camelcase-keys";
import SupportUtilityPaper from "./styled-components/SupportUtilityPaper";

export type ECommerceMetric = {
  accountId: string | null;
  accountName: string | null;
  aggregate: string | null;
  duration: string | null;
  errorMessage: string | null;
  id: string;
  isFba: boolean | null;
  tenantId: string | null;
  lastSync: string | null;
  startTime: string | null;
  vendor: string | null;
};

export default function ECommerceSync() {
  const baseURL = process.env.REACT_APP_API_URL + "v1/ecommercemetrics";
  const token = getToken();
  const [metrics, setMetrics] = React.useState<ECommerceMetric[]>([]);

  const fetch = () => {
    axios
      .get(baseURL, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setMetrics(response.data);
      });
  };

  const getRowStyle = (row: ECommerceMetric) => {
    return row.errorMessage
      ? { backgroundColor: "pink" }
      : row.lastSync && Date.parse(row.lastSync) < Date.now() - 60 * 60 * 1000
      ? { backgroundColor: "lightyellow" }
      : { backgroundColor: "lightgreen" };
  };

  React.useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.REACT_APP_SIGNALR_URL + "/api")
      .build();

    connection
      .start()
      .then(() => console.log("Connected to SignalR hub"))
      .catch((err) => console.error("Error connecting to hub:", err));

    connection.on("eCommerceMetricUpdate", (message) => {
      console.log("Received message:", message);
      let parsedMetric = JSON.parse(message);
      const camelCaseMetric = camelcaseKeys(parsedMetric);
      setMetrics((prevMetrics) => {
        const updatedMetrics = prevMetrics.map((metric) => {
          if (metric.id === camelCaseMetric.id) {
            return camelCaseMetric;
          }
          return metric;
        });
        return updatedMetrics;
      });
    });
    fetch();
  }, []);

  return (
    <SupportUtilityPaper>
      <Title>ECommerce Sync Status</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Account Name</TableCell>
            <TableCell>Tenant</TableCell>
            <TableCell>Vendor</TableCell>
            <TableCell>Aggregate</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>Last Sync</TableCell>
            <TableCell>Error Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((row) =>
            !row.isFba && row.aggregate === "Returns" ? null : (
              <TableRow style={getRowStyle(row)} key={row.id as React.Key}>
                <TableCell>{row.accountName}</TableCell>
                <TableCell>{row.tenantId}</TableCell>
                <TableCell>{row.vendor}</TableCell>
                <TableCell>{row.aggregate}</TableCell>
                <TableCell>
                  {row.startTime == null
                    ? ""
                    : format(
                        Date.parse(row.startTime),
                        "MM/dd/yyyy hh:mm:ss aa"
                      )}
                </TableCell>
                <TableCell>
                  {row.lastSync == null
                    ? ""
                    : format(
                        Date.parse(row.lastSync),
                        "MM/dd/yyyy hh:mm:ss aa"
                      )}
                </TableCell>
                <TableCell>{row.errorMessage}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </SupportUtilityPaper>
  );
}
