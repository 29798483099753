import { ServiceLog } from "../interfaces/service-logs/ServiceLog";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { format } from "date-fns";
import { LogLevels } from "../constants/service-logs/LogLevels";

interface SupportLogRowProps {
  log: ServiceLog;
}

const ServiceLogRow: React.FC<SupportLogRowProps> = ({ log }) => {
  const getRowColor = (logLevel: string) => {
    const numericLogLevel = Number(logLevel);

    switch (numericLogLevel) {
      case LogLevels.Trace:
        return "#33DBFF";
      case LogLevels.Debug:
        return "#3bdb13";
      case LogLevels.Information:
        return "#DA70D6";
      case LogLevels.Warning:
        return "#FFEB00";
      case LogLevels.Error:
        return "#FF0000";
      case LogLevels.Critical:
        return "#FFAE42";
      default:
        return "#ffffff";
    }
  };
 return (
   <TableRow sx={{ backgroundColor: getRowColor(log.LogLevel) }}>
     <TableCell align="center" sx={{ width: "175px" }}>
       {format(new Date(log.Created), "MM/dd hh:mm:ss aa")}
     </TableCell>

     <TableCell align="center">{log.Machine}</TableCell>
     <TableCell align="center">
       {LogLevels[log.LogLevel as keyof typeof LogLevels]}
     </TableCell>
     <TableCell align="center" sx={{ width: "150px" }}>
       {log.Service}
     </TableCell>
     <TableCell align="center">
       {log.MessageName ? log.MessageName : "N/A"}
     </TableCell>
     <TableCell
       align="center"
       sx={{
         maxWidth: "150px",
         whiteSpace: "nowrap",
         overflow: "hidden",
         textOverflow: "ellipsis",
         letterSpacing: "-0.5px",
       }}
     >
       <Tooltip title={log.Message} arrow placement="bottom-start">
         <span>{log.Message}</span>
       </Tooltip>
     </TableCell>
   </TableRow>
 );
};

export default ServiceLogRow;
