import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import DeadLetterQueueTicker from "./DeadLetterQueueTicker";
import HistoricalSaleSyncButton from "./HistoricalSaleSyncButton";
import ManualProductSyncButton from "./ManualProductSyncButton";
import ECommerceSyncButton from "./ECommerceSyncButton";
import SupportLogButton from "./ServiceLogsButton";
import TenantsButton from "./TenantsButton";
import ProductCatalogGeneratorButton from "./ProductCatalogGeneratorButton";


export const mainListItems = (
  <React.Fragment>
    <ManualProductSyncButton />
    <ProductCatalogGeneratorButton />
    <HistoricalSaleSyncButton />
    <ECommerceSyncButton />
    <SupportLogButton />
    <TenantsButton />
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Sync Dead Letters
    </ListSubheader>
    <DeadLetterQueueTicker></DeadLetterQueueTicker>
  </React.Fragment>
);
