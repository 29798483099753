import {
  Badge,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import useAxios from "axios-hooks";
import { useNavigate } from "react-router-dom";
import { getToken } from "../utilities/getToken";
import { paths } from "../constants/navigation/paths";

export type DeadLetterSummary = {
  sourceQueue: string;
  errorCount: number;
};

export default function DeadLetterQueueTicker() {
  const baseUrl = process.env.REACT_APP_API_URL + "v1/SyncErrors/Summary";
  const token = getToken();
  const [{ data, loading, error }] = useAxios({
    url: baseUrl,
    headers: { Authorization: `Bearer ${token}` },
  });

  const navigate = useNavigate();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error!</p>;

  const items = data.summaries.map((d: DeadLetterSummary) => {
    return (
      <ListItemButton
        key={d.sourceQueue}
        onClick={() => navigate(`${paths.SyncErrors}/${d.sourceQueue}`)}
      >
        <Badge color="error" badgeContent={d.errorCount}>
          <ErrorOutline color="primary" />
        </Badge>
        &nbsp;&nbsp;
        <ListItemText primary={`${d.sourceQueue}`} />
      </ListItemButton>
    );
  });

  return <div>{items}</div>;
}
