import { useEffect, useState } from "react";
import { LoadingOverlay } from "../common/LoadingOverlay"
import { Identity } from "../../interfaces/tenants/Identity";
import { getIdentities } from "../../services/identitiesService";
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TenantDetailsCard from "./TenantDetailsCard";
import { paths } from "../../constants/navigation/paths";
import { useNavigate } from "react-router-dom";

interface IdentitiesProps {
  tenantId: string;
}

export const Identities: React.FC<IdentitiesProps> = ({tenantId}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [identityData, setIdentityData] = useState<Identity[]>([]);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();

    const fetch = (tenantId: string) => {
      setIsLoading(true);
      getIdentities(tenantId)
        .then((response) => {
          const identities = response!.map((identity: Identity) => {
            setIsLoading(false);
            return {
              ...identity,
            };
          });
          setIdentityData(identities);
        });
    };

    const filterListbySearchTerm = (searchTerm: string) => {
      searchTerm = searchTerm.toUpperCase();
  
      const filteredIdentities = identityData.map((i) => {
        if (
          i.emailAddress?.toUpperCase().includes(searchTerm) ||
          i.firstName?.toUpperCase().includes(searchTerm) ||
          i.lastName?.toUpperCase().includes(searchTerm)
        ) {
          i.isHidden = false;
        } else {
          i.isHidden = true;
        }
  
        return i;
      });
  
      setIdentityData(filteredIdentities);
    };

    const searchIdentities = (searchText: string) => {
      setSearchText(searchText);
      filterListbySearchTerm(searchText);
    };

    const handleRowClick = (identityData: Identity) => {
      navigate(`${paths.Identities}/${tenantId}/${identityData.identityId}`, {
        state: { identityData },
      });
    };

    useEffect(() => fetch(tenantId), []);
    
    const rows: Identity[] = identityData;
    return (
      <TenantDetailsCard title="Identities">
        <div style={{ position: "relative" }}>
          {isLoading && <LoadingOverlay />}
          <Box display="flex" justifyContent="space-between" alignItems="end">
            <Button
              variant="contained"
              startIcon={<PersonAddIcon />}
              onClick={() => navigate(`${paths.Identities}/${tenantId}/new`)} 
              data-testid="createNewUserButton"
              >
              Create New User
            </Button>
            <TextField
              data-testid="searchTextField"
              placeholder="Search"
              value={searchText}
              onChange={(e) =>
              searchIdentities((e.target as HTMLInputElement).value as string)
              }
            />
          </Box>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Roles</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                if (!row.isHidden) {
                  return (
                    <TableRow
                      key={row.identityId}
                      onClick={() => handleRowClick(row)}
                      hover
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>{row.emailAddress}</TableCell>
                      <TableCell>{row.firstName} {row.lastName}</TableCell>
                      <TableCell>{row.roles?.find(r => r.tenantId === tenantId && r.locationId === undefined)?.identityRole}</TableCell>
                    </TableRow>
                  );
                }
              })}
            </TableBody>
          </Table>
        </div>
      </TenantDetailsCard>
);
}