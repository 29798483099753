import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import Title from "./Title";
import { ServiceLog } from "../interfaces/service-logs/ServiceLog";
import SearchIcon from "@mui/icons-material/Search";
import ServiceLogRow from "./ServiceLogRow";
import { SearchType } from "../constants/service-logs/SearchType";
import { fetchServiceLogs } from "../services/serviceLogService";
import { formatISO, subHours } from "date-fns";
import SupportUtilityPaper from "./styled-components/SupportUtilityPaper";
import { LoadingOverlay } from "./common/LoadingOverlay";
export default function ServiceLogs() {
  const [logs, setLogs] = useState<ServiceLog[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [searchType, setSearchType] = useState<string>(SearchType.tenantId);
  const [page, setPage] = useState<number>(0);

  const getLogs = async (isAppending = false) => {
    try {
      setLoading(true);
      const pastHour = formatISO(subHours(new Date(), 1));
      const logs = await fetchServiceLogs(`Created gt ${pastHour}`, page);

      setLogs((prevLogs) => (isAppending ? [...prevLogs, ...logs] : logs));

      setLogs(logs);
    } catch (err) {
      setError("Failed to load support logs");
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const pollLogs = async () => {
    try {
      const data = await fetchServiceLogs();

      setLogs((prevLogs) => {
        const newLogs = data.filter(
          (newLog) =>
            !prevLogs.some((existingLog) => existingLog.LogId === newLog.LogId)
        );
        return [...newLogs, ...prevLogs];
      });
    } catch (err) {
      setError("Failed to load support logs");
    }
  };

  const filteredLogs = logs.filter((log) => {
    const query = searchQuery.toLowerCase();
    if (searchType === SearchType.tenantId && log.TenantId) {
      return log.TenantId.toLowerCase().includes(query);
    }
    if (searchType === SearchType.serviceName && log.Service) {
      return log.Service.toLowerCase().includes(query);
    }
    return true;
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    getLogs(page > 1);
    if (page === 0) {
      const intervalId = setInterval(pollLogs, 10000);

      return () => clearInterval(intervalId);
    }
  }, [page]);

  return (
    <SupportUtilityPaper>
      <Title>Service Logs</Title>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <Select
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
          sx={{ mb: 2, width: "200px" }}
          size="small"
        >
          <MenuItem value={SearchType.tenantId}>Tenant ID</MenuItem>
          <MenuItem value={SearchType.serviceName}>Service Name</MenuItem>
        </Select>
        <Paper
          component="form"
          sx={{
            mb: 3,
            display: "flex",
            alignItems: "center",
            width: 200,
          }}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={`Search by ${searchType}`}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IconButton type="button" sx={{ p: 1.5 }}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      {loading && <LoadingOverlay />}
      <Table size="small">
        <TableHead>
          <TableRow>
            <TablePagination
              count={-1}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={200}
              rowsPerPageOptions={[]}
            />
          </TableRow>
          <TableRow>
            <TableCell align="center">Created</TableCell>
            <TableCell align="center">Machine</TableCell>
            <TableCell align="center">Log Level</TableCell>
            <TableCell align="center">Service Method</TableCell>
            <TableCell align="center">Message Name</TableCell>
            <TableCell align="center">Message</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredLogs.map((log) => (
            <ServiceLogRow key={log.LogId} log={log} />
          ))}
        </TableBody>
      </Table>
    </SupportUtilityPaper>
  );
}
