import { Fragment, useState } from "react";
import { deleteTenantToTenantPermission, getPermissionsList, putTenantToTenantPermission } from "../../services/tenantToTenantPermissionsService";
import { Box, Button, List, ListItemButton, Modal } from "@mui/material";
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { PermissionOption } from "../../interfaces/tenants/Tenant";
import { SaveTenantPermissionResponse } from "../../constants/responses/SaveTenantPermissionResponse";
import { LoadingOverlay } from "../common/LoadingOverlay";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  interface PermissionListProps {
    tenantLabel: string | null;
    tenantId: string;
    permissedTenantId: string | null;
    selectedPermissions: string[] | null;
    callback: (response: SaveTenantPermissionResponse | null) => void;
  }

export const TenantToTenantPermissionListModal: React.FC<PermissionListProps> = ({tenantLabel, tenantId, permissedTenantId, selectedPermissions, callback}) => {
    const [open, setOpen] = useState(false);
    const [permissions, setPermissions] = useState([] as PermissionOption[]);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleOpenModal = (enabledPermissions: string[]) => {
      fetchPermissions(enabledPermissions);
      setOpen(true);
    };

    const handleCloseModalWithoutSave = () => {
        setOpen(false);
    }

    const handleCloseModal = () => {

        if (permissions.filter((p) => p.isSelected).length === 0) {
            deleteTenantToTenantPermission(tenantId, permissedTenantId)
            .then(() => {
                callback(null);
                setOpen(false);
            });
        } else {
            putTenantToTenantPermission({tenantId: permissedTenantId!, permissions: permissions.filter((p) => p.isSelected).map((p) => p.label)}, tenantId)
            .then((response) => {
                callback(response);
                setOpen(false);
            });
        }
    };

    const handlePermissionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const permission = event.currentTarget.textContent!;
        const updatedPermissions = permissions.map((p) => {
          if (p.label === permission) {
            return { ...p, isSelected: !p.isSelected };
          }
          return p;
        });
        setPermissions(updatedPermissions);
      };
  
    const fetchPermissions = (enabledPermissions: string[]) => {
      // fetch permissions
      setIsLoading(true);
      getPermissionsList()
      .then((permissions : string[] | null) => {
        setPermissions(
          permissions!.map((permission) => {
            return { value: permission, label: permission, isSelected: enabledPermissions.includes(permission) };
          }
        ))
        setIsLoading(false);
      });
    };
  
    return (
      <Fragment>
        <Button disabled={!tenantLabel || tenantLabel.length === 0} onClick={() => handleOpenModal(selectedPermissions || [])}><AppRegistrationIcon/> Edit {tenantLabel}</Button>
        <Modal
          open={open}
          onClose={handleCloseModalWithoutSave}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 200 }}>
            {isLoading && <LoadingOverlay />}
            <List>
              {permissions.map((permission) => (
                <ListItemButton key={permission.label} onClick={handlePermissionClick} selected={permission.isSelected}>{permission.label}</ListItemButton >
              ))}
            </List>
  
            <Button onClick={handleCloseModal}>Done</Button>
          </Box>
        </Modal>
      </Fragment>
    );
  }