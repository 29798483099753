import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import FortIcon from "@mui/icons-material/Fort";
import { useNavigate } from "react-router-dom";
import { paths } from "../constants/navigation/paths";

export default function TenantsButton() {
  const navigate = useNavigate();

  return (
    <ListItemButton>
      <ListItemIcon>
        <FortIcon />
      </ListItemIcon>
      <ListItemText
        primary="Tenants"
        onClick={() => navigate(`${paths.Tenants}`)}
      />
    </ListItemButton>
  );
}
