import axios from "axios";
import { getToken } from "../utilities/getToken";
import { Tenant } from "../interfaces/tenants/Tenant";

const baseURL = process.env.REACT_APP_API_URL;

export const updateTenant = async (
  tenant: Tenant,
  tenantId?: string
): Promise<Tenant | null> => {
  const apiToken = getToken();
  try {
    const response = await axios.put<Tenant>(
      `${baseURL}v2/Tenants/Support/${tenantId}`,
      tenant,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        },
      }
    );
    const updatedTenant = response.data;
    return updatedTenant;
  } catch (error) {
    console.error("Failed to update tenant:", error);
    return null;
  }
};
