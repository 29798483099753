import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import axios from 'axios';
import { DeadLetterMessage } from "./DeadLetters";
import { getToken } from "../utilities/getToken";

export type DeadLetterRequeueAllProps = {
    disabled: boolean
    queueName: string | undefined,
    messages: DeadLetterMessage[]
}

export default function DeadLetterRequeueAll(props: DeadLetterRequeueAllProps) {
    const [open, setOpen] = useState(false);
    const messagesToSend = 25;
    const baseURL = process.env.REACT_APP_API_URL + `v1/SyncErrors`;
    const token = getToken();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOk = () => {
        if (!props.messages || props.messages.length === 0) {
            return;
        }
        let messages = props.messages.slice(0, messagesToSend);
        let partitionKey = props.queueName ?? "Unknown";

        axios.post(`${baseURL}/${partitionKey}/requeueAll`, messages, {
            'headers': {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        }).then((response) => {
            if (response.status === 200)
                window.location.reload();
            if (response.status !== 200)
                alert(`${response.status} ${response.statusText}`)
        });
    };

    return (
        <div>
            <Button disabled={props.disabled} variant="outlined" color="inherit" onClick={globalThis.unsafe ? handleOk : handleClickOpen}>
                Requeue All
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Requeue Top {messagesToSend > props.messages!.length ? props.messages!.length : messagesToSend} Errors</DialogTitle>
                <DialogContent>
                    Are you sure you want to requeue {messagesToSend > props.messages!.length ? props.messages!.length : messagesToSend} messages? Note that if you made any changes to any of the original message bodies, they WILL be sent.
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        No
                    </Button>
                    <Button onClick={handleOk}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div>);
}