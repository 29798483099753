import axios from "axios";
import { getToken } from "../utilities/getToken";
import { Identity } from "../interfaces/tenants/Identity";
import { SaveIdentityRequest } from "../constants/requests/SaveIdentityRequest";

const baseURL = process.env.REACT_APP_API_URL;

export const getIdentity = async (
  identityId: string
): Promise<Identity | null> => {
  const apiToken = getToken();
  try {
    const response = await axios.get<Identity>(
      `${baseURL}v3/Identities/Support/${identityId}`,
      {
        headers: {
          Authorization: `Bearer ${apiToken}`,
        }
      }
    );
    return response.data.roles === undefined ? {...response.data, roles: []} : response.data;
  } catch (error) {
    console.error("Failed to retrieve identity:", error);
    return null;
  }
};

export const getIdentities = async (
    tenantId: string
  ): Promise<Identity[] | null> => {
    const apiToken = getToken();
    try {
      const response = await axios.get<Identity[]>(
        `${baseURL}v3/Identities?tenantId=${tenantId}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      var tenants = response.data;
      return tenants;
    } catch (error) {
      console.error("Failed to retrieve identities:", error);
      return null;
    }
  };

  export const putIdentity = async (
    saveIdentityRequest: SaveIdentityRequest,
    identityId: string | null
  ): Promise<Identity | null> => {
    const apiToken = getToken();
    try {
      const response = await axios.put<SaveIdentityRequest, Identity>(
        `${baseURL}v3/Identities/Support/${identityId}`,
        saveIdentityRequest,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          }
        }
      );
      return response;
    } catch (error) {
      console.error("Failed to save identity:", error);
      return null;
    }
  };